import React, {Component} from 'react';
import SubsystemNavItem from "./SubsystemNavItem";
import {CONSTANTS} from "../../../../constants";

const K_ICON = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.0625 54.1909H12.7322V9.51985H7.0625V4.31086H31.5232V9.51985H25.8535V28.3827H33.1431L38.4078 16.5441C39.5418 14.0185 40.6487 11.9139 41.7286 10.2302C42.8086 8.54646 43.9155 7.20475 45.0495 6.20504C46.1834 5.15272 47.3713 4.41609 48.6133 3.99516C49.8552 3.57423 51.1781 3.36377 52.5821 3.36377C54.8499 3.36377 56.6588 4.02147 58.0088 5.33687C59.4127 6.65228 60.1146 8.30968 60.1146 10.3091C60.1146 12.519 59.4667 14.2553 58.1707 15.5181C56.9288 16.7283 55.3089 17.3333 53.311 17.3333C51.5291 17.3333 50.1252 16.8861 49.0992 15.9916C48.1273 15.0445 47.5063 13.9396 47.2363 12.6768H46.9124C46.4264 13.4134 45.8594 14.3342 45.2114 15.4392C44.6175 16.5441 44.0235 17.7806 43.4295 19.1486L38.5698 29.8823C39.9197 29.9875 41.1347 30.2243 42.2146 30.5926C43.2946 30.9083 44.2935 31.4082 45.2114 32.0922C46.1294 32.7762 46.9664 33.618 47.7223 34.6177C48.4783 35.6174 49.2342 36.8539 49.9902 38.3272L58.0088 54.1909H63.0305V59.3999H46.1834L33.1431 33.8285H25.8535V54.1909H31.5232V59.3999H7.0625V54.1909Z"
      fill="#999CE3"/>
    <path
      d="M5.43262 52.9446H11.1023V8.27352H5.43262V3.06452H29.8933V8.27352H24.2236V27.1364H31.5132L36.7779 15.2978C37.9119 12.7722 39.0188 10.6675 40.0988 8.98383C41.1787 7.30012 42.2856 5.95841 43.4196 4.9587C44.5535 3.90638 45.7414 3.16976 46.9834 2.74883C48.2253 2.3279 49.5482 2.11743 50.9522 2.11743C53.22 2.11743 55.0289 2.77513 56.3789 4.09053C57.7828 5.40594 58.4848 7.06335 58.4848 9.06276C58.4848 11.2726 57.8368 13.009 56.5409 14.2717C55.2989 15.4819 53.679 16.087 51.6811 16.087C49.8992 16.087 48.4953 15.6398 47.4694 14.7453C46.4974 13.7982 45.8764 12.6933 45.6065 11.4305H45.2825C44.7965 12.1671 44.2295 13.0879 43.5816 14.1928C42.9876 15.2978 42.3936 16.5342 41.7997 17.9023L36.9399 28.6359C38.2898 28.7412 39.5048 28.978 40.5847 29.3463C41.6647 29.662 42.6636 30.1618 43.5816 30.8458C44.4995 31.5298 45.3365 32.3717 46.0924 33.3714C46.8484 34.3711 47.6043 35.6076 48.3603 37.0808L56.3789 52.9446H61.4006V58.1536H44.5535L31.5132 32.5822H24.2236V52.9446H29.8933V58.1536H5.43262V52.9446Z"
      fill="white"/>
  </svg>
)

const E_ICON = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.2369 59.2941C28.0105 59.2941 25.1484 58.9899 22.6505 58.3814C20.2047 57.7222 18.1492 56.8855 16.4839 55.8713C14.8187 54.8065 13.5437 53.6148 12.6591 52.2964C11.8265 50.9273 11.4102 49.5075 11.4102 48.037C11.4102 46.0086 11.9826 44.4113 13.1274 43.2451C14.2723 42.0788 15.7814 41.4956 17.6548 41.4956C19.7364 41.4956 21.2975 42.1041 22.3383 43.3211C23.4311 44.4874 23.9775 45.9326 23.9775 47.6567C23.9775 48.6708 23.7173 49.6343 23.197 50.547C22.6766 51.409 22.0001 52.0936 21.1674 52.6007V53.057C21.948 53.3613 22.9888 53.6148 24.2898 53.8177C25.6428 54.0205 27.1519 54.1219 28.8171 54.1219C37.976 54.1219 42.5554 49.5075 42.5554 40.2786V33.8134H24.6801V28.3369H42.5554V24.0775C42.5554 21.6435 42.2692 19.5391 41.6968 17.7643C41.1243 15.9896 40.3177 14.5444 39.2769 13.4288C38.2362 12.2625 37.0133 11.4005 35.6082 10.8427C34.2032 10.2849 32.642 10.006 30.9247 10.006C28.0105 10.006 25.7208 10.6399 24.0556 11.9076C22.4424 13.1753 21.6358 15.0261 21.6358 17.4601V20.9589H12.581V5.29019H21.6358V9.70177H22.0261C23.1709 8.07912 24.6801 6.78607 26.5535 5.82262C28.4789 4.85917 31.0548 4.37744 34.2812 4.37744C37.4036 4.37744 40.3177 4.96058 43.0237 6.12686C45.7298 7.29315 48.0715 9.01722 50.049 11.2991C52.0785 13.5809 53.6397 16.4459 54.7325 19.8941C55.8773 23.2915 56.4498 27.2467 56.4498 31.7597C56.4498 36.3234 55.7993 40.3294 54.4983 43.7775C53.2494 47.2256 51.4801 50.116 49.1903 52.4486C46.9527 54.7304 44.2987 56.4545 41.2284 57.6208C38.1581 58.7363 34.8276 59.2941 31.2369 59.2941Z"
      fill="#E89393"/>
    <path
      d="M32.6744 58.0932C29.448 58.0932 26.5859 57.7889 24.088 57.1804C21.6422 56.5212 19.5867 55.6846 17.9214 54.6704C16.2562 53.6055 14.9812 52.4139 14.0966 51.0955C13.264 49.7264 12.8477 48.3066 12.8477 46.836C12.8477 44.8077 13.4201 43.2104 14.5649 42.0441C15.7098 40.8778 17.2189 40.2947 19.0923 40.2947C21.1739 40.2947 22.735 40.9032 23.7758 42.1202C24.8686 43.2865 25.415 44.7317 25.415 46.4557C25.415 47.4699 25.1548 48.4333 24.6345 49.3461C24.1141 50.2081 23.4376 50.8927 22.6049 51.3997V51.8561C23.3855 52.1604 24.4263 52.4139 25.7273 52.6167C27.0803 52.8196 28.5894 52.921 30.2546 52.921C39.4135 52.921 43.9929 48.3066 43.9929 39.0777V32.6125H26.1176V27.136H43.9929V22.8765C43.9929 20.4426 43.7067 18.3382 43.1343 16.5634C42.5618 14.7886 41.7552 13.3435 40.7144 12.2279C39.6737 11.0616 38.4508 10.1996 37.0457 9.64178C35.6407 9.08399 34.0795 8.80509 32.3622 8.80509C29.448 8.80509 27.1583 9.43894 25.4931 10.7066C23.8799 11.9743 23.0733 13.8252 23.0733 16.2592V19.758H14.0185V4.08926H23.0733V8.50084H23.4636C24.6084 6.87819 26.1176 5.58514 27.991 4.62169C29.9164 3.65824 32.4923 3.17651 35.7187 3.17651C38.8411 3.17651 41.7552 3.75965 44.4612 4.92594C47.1673 6.09222 49.509 7.81629 51.4865 10.0981C53.516 12.38 55.0772 15.245 56.17 18.6931C57.3148 22.0906 57.8873 26.0458 57.8873 30.5588C57.8873 35.1225 57.2368 39.1284 55.9358 42.5766C54.6869 46.0247 52.9176 48.9151 50.6278 51.2476C48.3902 53.5295 45.7362 55.2535 42.6659 56.4198C39.5956 57.5354 36.2651 58.0932 32.6744 58.0932Z"
      fill="white"/>
  </svg>
)

const B_ICON = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.69336 54.5787H14.335V10.129H8.69336V4.9458H37.7879C41.0654 4.9458 43.8862 5.28611 46.2503 5.96673C48.6144 6.59499 50.5487 7.48504 52.0531 8.63685C53.6113 9.78867 54.7396 11.1499 55.4381 12.7206C56.1903 14.2912 56.5664 15.9928 56.5664 17.8252C56.5664 19.71 56.2171 21.3854 55.5187 22.8513C54.8739 24.3173 53.9874 25.5738 52.859 26.6209C51.7307 27.6157 50.4143 28.4534 48.9099 29.134C47.4592 29.8146 45.9548 30.312 44.3966 30.6261V31.0188C46.0085 31.2282 47.6741 31.647 49.3935 32.2753C51.1128 32.9036 52.671 33.7674 54.068 34.8669C55.4649 35.9664 56.5932 37.3276 57.4529 38.9506C58.3663 40.5213 58.823 42.3799 58.823 44.5265C58.823 49.3955 57.2111 53.1651 53.9874 55.8352C50.7636 58.453 45.9011 59.7619 39.3998 59.7619H8.69336V54.5787ZM27.3912 54.186H36.3372C39.2386 54.186 41.4684 53.4792 43.0265 52.0656C44.6384 50.652 45.4444 48.6625 45.4444 46.0971V42.4061C45.4444 39.8407 44.6384 37.8512 43.0265 36.4376C41.4684 35.024 39.2386 34.3172 36.3372 34.3172H27.3912V54.186ZM27.3912 28.8984H34.8865C40.4207 28.8984 43.1877 26.4115 43.1877 21.4377V17.9823C43.1877 13.0085 40.4207 10.5216 34.8865 10.5216H27.3912V28.8984Z"
      fill="#CB638A"/>
    <path
      d="M10.1777 53.339H15.8193V8.88924H10.1777V3.70605H39.2723C42.5498 3.70605 45.3706 4.04636 47.7347 4.72698C50.0988 5.35525 52.033 6.24529 53.5375 7.39711C55.0956 8.54893 56.224 9.91017 56.9224 11.4808C57.6747 13.0515 58.0508 14.753 58.0508 16.5855C58.0508 18.4703 57.7015 20.1456 57.003 21.6116C56.3583 23.0775 55.4717 24.3341 54.3434 25.3812C53.2151 26.3759 51.8987 27.2136 50.3943 27.8942C48.9436 28.5749 47.4392 29.0722 45.881 29.3864V29.779C47.4929 29.9885 49.1585 30.4073 50.8779 31.0356C52.5972 31.6638 54.1554 32.5277 55.5523 33.6272C56.9493 34.7266 58.0776 36.0879 58.9373 37.7109C59.8507 39.2815 60.3074 41.1402 60.3074 43.2867C60.3074 48.1558 58.6955 51.9254 55.4717 54.5955C52.248 57.2133 47.3854 58.5221 40.8842 58.5221H10.1777V53.339ZM28.8756 52.9463H37.8216C40.723 52.9463 42.9528 52.2395 44.5109 50.8259C46.1228 49.4123 46.9287 47.4228 46.9287 44.8574V41.1663C46.9287 38.6009 46.1228 36.6114 44.5109 35.1978C42.9528 33.7842 40.723 33.0774 37.8216 33.0774H28.8756V52.9463ZM28.8756 27.6586H36.3709C41.905 27.6586 44.6721 25.1718 44.6721 20.198V16.7425C44.6721 11.7688 41.905 9.2819 36.3709 9.2819H28.8756V27.6586Z"
      fill="white"/>
  </svg>

)

const M_ICON = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.08691 57.4184H18.6187V52.1856H13.0906V13.3248H13.5645L18.3817 24.7137L30.3855 49.1845L42.3892 24.9446L47.2854 13.3248H47.7593V52.1856H42.2312V57.4184H66.8705V52.1856H61.3424V8.93855H66.8705V3.70581H45.627L34.413 28.7922H34.0971L21.8565 3.70581H1.08691V8.93855H6.61494V52.1856H1.08691V57.4184Z"
      fill="#638DF9"/>
    <path
      d="M2.54102 59.6459H20.0728V54.4132H14.5447V15.5524H15.0186L19.8358 26.9413L31.8396 51.412L43.8433 27.1721L48.7395 15.5524H49.2134V54.4132H43.6853V59.6459H68.3246V54.4132H62.7965V11.1661H68.3246V5.93335H47.0811L35.8671 31.0197H35.5512L23.3106 5.93335H2.54102V11.1661H8.06904V54.4132H2.54102V59.6459Z"
      fill="white"/>
  </svg>
)

const P_ICON = (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33008 56.5587H27.0121V51.9707H21.6841V9.93874H43.1441V51.9707H37.8161V56.5587H59.4981V51.9707H54.1701V9.49474H59.4981V4.90674H5.33008V9.49474H10.6581V51.9707H5.33008V56.5587Z"
      fill="#3FB9B1"/>
    <path
      d="M3.33008 54.5685H25.0121V49.9805H19.6841V7.9485H41.1441V49.9805H35.8161V54.5685H57.4981V49.9805H52.1701V7.5045H57.4981V2.9165H3.33008V7.5045H8.65808V49.9805H3.33008V54.5685Z"
      fill="white"/>
  </svg>
)

class SubsystemNav extends Component {
  render() {
    return (
      <ul className={'subsystem-nav-item-list'}>
        <SubsystemNavItem id={'courses'}
                          icon={K_ICON}
                          name={'Образовательные курсы'}
                          url={CONSTANTS.coursePassageUrl}/>
        <SubsystemNavItem id={'wiki'}
                          icon={E_ICON}
                          name={'Энциклопедия русского языка и культуры'}
                          url={CONSTANTS.wikiUrl} />

        <SubsystemNavItem id={'video'}
                          icon={B_ICON}
                          name={'Образовательные видеозаписи'}
                          url={CONSTANTS.videoUrl}/>

        <SubsystemNavItem id={'materials'}
                          icon={M_ICON}
                          name={'Методические материалы'}
                          url={CONSTANTS.coursePassageUrl + '/material'}/>

        <SubsystemNavItem id={'practices'}
                          icon={P_ICON}
                          name={'Лучшие практики и модели'}
                          url={CONSTANTS.coursePassageUrl + '/practice'}
        />
      </ul>
    );
  }
}

export default SubsystemNav;
