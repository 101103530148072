import React, {Component} from 'react';
import {Container, HomePageHeader, Col, Row} from "akipkro-ui";
import {apiPost, connectComponent, errorActions} from "akipkro-share-client";
import SubsystemNav from "./subsystemNav/SubsystemNav";
import CoursesPreview from "./coursesPreview/CoursesPreview";
import WikiPreview from "./wikiPreview/WikiPreview";
import VideoPreview from "./videoPreview/VideoPreview";
import MaterialPreview from "./materialPreview/MaterialPreview";
import PracticePreview from "./practicePriview/PracticePreview";

interface HomePageState {
  selectedLang: string
}

class HomePage extends Component<{}, HomePageState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedLang: JSON.parse(localStorage.getItem('lang')) ? JSON.parse(localStorage.getItem('lang')).langId : null,
    }
  }

  render() {
    let {selectedLang} = this.state
    return (
      <div>
        <HomePageHeader className={'home-page-header'}>
          <Container>
            <Row>
              <Col xs={12} xl={8}>
                <h1 className={'header mb-secondary'}>
                  Открытая образовательная площадка для&nbsp;изучения русского&nbsp;языка
                </h1>
                <p className={'description mb-0'}>
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                </p>
              </Col>
            </Row>
            <SubsystemNav/>
          </Container>
        </HomePageHeader>

        <CoursesPreview selectedLang={selectedLang}/>
        <WikiPreview selectedLang={selectedLang}/>
        <VideoPreview selectedLang={selectedLang}/>
        <MaterialPreview selectedLang={selectedLang}/>
        <PracticePreview selectedLang={selectedLang}/>
      </div>
    );
  }
}

export default HomePage
