import {createStore} from "redux";
import {App, createReducers, React} from "akipkro-share-client";
import {composeWithDevTools} from "redux-devtools-extension";
import SubsystemInfo from "akipkro-share-client/lib/app/dto/SubsystemInfo";
import MainLayout from "./MainLayout";
import {CONSTANTS} from "../../constants";

const store = createStore(
  createReducers({
  }),
  composeWithDevTools()
)
// TODO: Get info from database
const SUBSYSTEM: SubsystemInfo = {
  subsystemId: CONSTANTS.clientId,
  name: CONSTANTS.clientName,
  imageUrl: null
}

class Spa extends React.Component {
  render() {
    return (
      <App store={store} subsystem={SUBSYSTEM} isContentContainer={false}>
        <MainLayout/>
      </App>
    );
  }
}

export default Spa;
