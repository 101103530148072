import React, {Component} from 'react';
import {mdiShieldCheck} from "@mdi/js";
import {COLORS, Icon, IRO_LOGO} from "akipkro-ui";

interface CourseHighQualityIconProps {
  className?: string
}

class CourseHighQualityIcon extends Component<CourseHighQualityIconProps> {
  render() {
    let {className} = this.props
    return <div className={'high-quality-icon mr-2'}>{IRO_LOGO}</div>
  }
}

export default CourseHighQualityIcon;