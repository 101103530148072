import * as React from "react";
import {COLORS, Icon} from "akipkro-ui";
import {mdiGaugeEmpty, mdiGaugeFull, mdiGaugeLow} from "@mdi/js";
import {CourseLevel} from "akipkro-share";

const COURSE_LEVEL_COLOR = {
  [CourseLevel.EASY]: COLORS.green,
  [CourseLevel.MEDIUM]: COLORS.yellow,
  [CourseLevel.HARD]: COLORS.indigo
}

const COURSE_LEVEL_PATH = {
  [CourseLevel.EASY]: mdiGaugeEmpty,
  [CourseLevel.MEDIUM]: mdiGaugeLow,
  [CourseLevel.HARD]: mdiGaugeFull
}

function getLevelIcon(levelId: CourseLevel, className?: string) {
  return <Icon path={COURSE_LEVEL_PATH[levelId]} color={COURSE_LEVEL_COLOR[levelId]} className={className}/>
}

export {COURSE_LEVEL_COLOR, getLevelIcon}
