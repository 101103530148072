import React, {Component} from 'react';
import {apiGet, connectComponent, errorActions, getSelectedLang} from "akipkro-share-client";
import {Button, Col, Container, Icon, Row} from "akipkro-ui";
import {mdiChevronRight} from "@mdi/js";
import MaterialCardList from "../../../share/material/MaterialCardList";
import {CONSTANTS} from "../../../../constants";
import {MaterialDTO} from "akipkro-share";

const ICON = (
  <svg width="365" height="316" viewBox="0 0 365 316" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path
        d="M30.0234 277.51H111.076V252.675H85.5189V68.2405H87.7095L109.981 122.293L165.476 238.432L220.972 123.388L243.608 68.2405H245.799V252.675H220.241V277.51H334.153V252.675H308.596V47.4231H334.153V22.5884H235.941L184.096 141.649H182.636L126.045 22.5884H30.0234V47.4231H55.5806V252.675H30.0234V277.51Z"
        fill="#D5DEF7"/>
    </g>
    <path
      d="M36.7485 288.083H117.801V263.248H92.244V78.8133H94.4346L116.706 132.865L172.201 249.004L227.697 133.961L250.333 78.8133H252.524V263.248H226.967V288.083H340.878V263.248H315.321V57.9959H340.878V33.1611H242.666L190.821 152.222H189.361L132.77 33.1611H36.7485V57.9959H62.3057V263.248H36.7485V288.083Z"
      fill="#5689FF"/>
    <defs>
      <filter id="filter0_d" x="0.0234375" y="0.588379" width="364.13" height="314.922" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="15"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>

)

interface MaterialPreviewProps {
  errorActions: any
}

interface MaterialPreviewState {
  materials: MaterialDTO[]
}

class MaterialPreview extends Component<MaterialPreviewProps, MaterialPreviewState> {
  constructor(props) {
    super(props);
    this.state = {
      materials: null
    }
  }

  componentDidMount(): void {
    this.loadData()
  }

  loadData = async () => {
    const {errorActions} = this.props
    let materials: MaterialDTO[] = await apiGet(
      CONSTANTS.coursePassageUrl + '/api/material/recommended/3',
      {
        langId: getSelectedLang() || '',
      },
      errorActions.setError
    )
    this.setState({materials})
  }

  render() {
    let {materials} = this.state
    return (
      <div className={'material-preview'}>
        <div className={'material-preview-header'}>
          <Container>
            <Row>
              <Col xs={12} md={8} className={'order-1 order-sm-0'}>
                <h2 className={'mb-primary header'}>
                  <a href={CONSTANTS.coursePassageUrl + '/material'} className={'text-blueCustom'}>
                    Методические материалы
                  </a>
                </h2>
                <p className={'mb-primary description'}>
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                </p>
              </Col>
              <Col xs={12} md={4} className={'text-center flex-shrink-0 order-0 order-sm-1'}>
                <div className={'material-icon'}>
                  <a href={CONSTANTS.coursePassageUrl + '/material'}>
                    {ICON}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {materials &&
        <div className={'material-preview-cards'}>
          <Container className={'d-flex flex-column '}>
            <MaterialCardList materials={materials}/>
            <div className={'my-primary d-flex justify-content-center'}>
              <Button tag={'a'} href={CONSTANTS.coursePassageUrl + '/material'} color={'blueCustom'}
                      className={'d-flex align-items-center'}>
                Все материалы
                <Icon path={mdiChevronRight} color={'white'} className={'ml-secondary'}/>
              </Button>
            </div>
          </Container>
        </div>
        }
      </div>
    );
  }
}

export default connectComponent(
  (state, props) => ({}),
  dispatch => ({
    errorActions: errorActions(dispatch)
  }),
  MaterialPreview
);
