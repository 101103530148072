import React, {Component} from 'react';
import {connectComponent, AuthorizedRoute, PrivateItem} from "akipkro-share-client";
import {Route, Switch} from "react-router-dom";
import {Container} from "akipkro-ui";
import HomePage from "./home/HomePage";

interface Props {
  menuActions: any
}

class MainLayout extends Component<Props> {
  render() {
    let {menuActions} = this.props
    return (
      <div className={'mb-primary'}>
        <Switch>
          <Route exact path={'/'} component={HomePage}/>
        </Switch>
      </div>
    );
  }
}

export default connectComponent(
  (state, props) => ({}),
  dispatch => ({}),
  MainLayout
);