import React, {Component} from 'react';
import {getFullUrl} from "akipkro-share-client";

interface SubsystemNavItemProps {
  id: string,
  icon: any,
  name: string,
  url: string
}

class SubsystemNavItem extends Component<SubsystemNavItemProps> {
  render() {
    let {id, icon, name, url} = this.props
    return (
      <li className={'subsystem-nav-item ' + id}>
        <a href={getFullUrl(url)}>
          <div className={'icon'}>{icon}</div>
          <p className={'name'}>
            {name}
          </p>
        </a>
      </li>
    );
  }
}

export default SubsystemNavItem;
