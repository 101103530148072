import React, {Component} from 'react';
import CourseCard from "./CourseCard";
import {CONSTANTS} from "../../../constants";
import {CourseDTO} from "akipkro-share";

interface CourseCardListProps {
  courses: CourseDTO[]
}

class CourseCardList extends Component<CourseCardListProps> {
  render() {
    let {courses} = this.props
    let items = courses.map(course => (
      <CourseCard key={course.courseId} course={course} url={CONSTANTS.coursePassageUrl + '/course/' + course.courseId + '/preview'}/>
    ))
    return <div className={'course-card-list'}>{items}</div>
  }
}

export default CourseCardList;
