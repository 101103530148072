import React, {Component} from 'react';
import {COLORS, Icon} from 'akipkro-ui';
import CourseHighQualityIcon from "./CourseHighQualityIcon";
import {mdiCalendarMonth} from "@mdi/js";
import CourseCardImage from "./CourseCardImage";
import CourseCardFooterItem from "./CourseCardFooterItem";
import CoursePeriodUtils from "./CoursePeriodUtils";
import {COURSE_LEVEL_NAME, CourseDTO, CourseLevel, CoursePeriodDTO} from "akipkro-share";
import {getLevelIcon} from "./CourseLevelAdditionalName";

interface CourseCardProps {
  course: CourseDTO,
  url: string
}

const ICON_RIGHT_MARGIN = 'mr-2'

class CourseCard extends Component<CourseCardProps> {
  getLevels = (course: CourseDTO) => {
    // TODO: Убрать?
    return null;


    let items = course.levelIds.map(levelId => (
      <div key={levelId} className={'d-flex align-items-center mr-secondary level'}>
        {getLevelIcon(levelId as CourseLevel, ICON_RIGHT_MARGIN)}
        {COURSE_LEVEL_NAME[levelId]}
      </div>
    ))

    return (
      <CourseCardFooterItem>
        {items}
      </CourseCardFooterItem>
    )
  }

  getPeriod = (course: CourseDTO) => {
    let content: any = (
      <CourseCardFooterItem className={'period'}>
        <Icon path={mdiCalendarMonth} color={COLORS.gray600} className={ICON_RIGHT_MARGIN}/>
        {course.period ?
          CoursePeriodUtils.getPeriodString(course.period.dateBegin, course.period.dateEnd) :
          'периоды отсутствуют'
        }
      </CourseCardFooterItem>
    )

    if (course.period) {
      if (course.period.dateEnd) {
        content = (
          <CourseCardFooterItem className={'period'}>
            <Icon path={mdiCalendarMonth} color={COLORS.gray600} className={ICON_RIGHT_MARGIN}/>
            {CoursePeriodUtils.getPeriodString(course.period.dateBegin, course.period.dateEnd)}
          </CourseCardFooterItem>
        )
      } else {
        content = null
      }
    }
    return (content)
  }

  getPercentDone = (course: CourseDTO) => {
    const period: CoursePeriodDTO = course.period
    if (period && period.isStudent) {
      const isDone = CoursePeriodUtils.isDone(period.itemsDone, period.itemsTotal)
      return (
        <CourseCardFooterItem>
          {CoursePeriodUtils.getPercentIcon(period.itemsDone, period.itemsTotal, ICON_RIGHT_MARGIN)}
          <span className={isDone ? 'text-success' : 'text-primary'}>{CoursePeriodUtils.getPercentDoneText(period.itemsDone, period.itemsTotal)}</span>
        </CourseCardFooterItem>
      )
    } else {
      return null
    }
  }

  getQuality = (course: CourseDTO) => {
    if (course.isHighQuality) {
      return (
        <CourseCardFooterItem className={'align-items-start'}>
          <CourseHighQualityIcon className={'flex-grow-0 flex-shrink-0 ' + ICON_RIGHT_MARGIN}/>
          <span className={'text-secondary'}>
            Курс {course.isPlatformCourse ? 'разработан' : 'рекомендован'} Алтайским Институтом Развития Образования
          </span>
        </CourseCardFooterItem>
      )
    } else {
      return null
    }
  }


  render() {
    let {course, url} = this.props
    return (
      <a href={url} className={'course-card'}>
        <div className={'block border-bottom flex-grow-0 d-flex align-items-center'}>
          <div className={'catalog-name flex-grow-1'}>
            {course.catalogName}
          </div>
        </div>
        <CourseCardImage imageFileName={course.imageFileName} />
        <div className={'block border-top flex-grow-1'}>
          <h3 className={'mb-3'}>
            {course.name}
          </h3>
          <p className={'description mb-0'}>
            {course.description}
          </p>
        </div>
        <div className={'flex-grow-0 block'}>
          {this.getLevels(course)}
          {this.getPeriod(course)}
          {this.getPercentDone(course)}
          {this.getQuality(course)}
        </div>
      </a>
    );
  }
}

export default CourseCard;
