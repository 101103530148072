import moment = require("moment");
import {Icon, COLORS} from "akipkro-ui";
import {mdiCheckboxMarkedCircle, mdiTimerSand} from "@mdi/js";
import React from "react";

function isDone(itemsDone: number, itemsTotal: number) {
  return itemsDone === itemsTotal
}

function getPercentDoneText(itemsDone: number, itemsTotal: number) {
  return (isDone(itemsDone, itemsTotal) ? '100' : Math.round(100.0 * itemsDone / itemsTotal).toString()) + '% пройдено'
}

function getPercentIcon(itemsDone: number, itemsTotal: number, className?: string) {
  return isDone(itemsDone, itemsTotal) ?
    <Icon path={mdiCheckboxMarkedCircle} color={COLORS.green} className={className}/> :
    <Icon path={mdiTimerSand} color={COLORS.blue} className={className}/>
}

function getPeriodString(dateBegin: Date, dateEnd: Date): string {
  return `с ${moment(dateBegin).format('DD.MM.YYYY') + (dateEnd ? ' по ' + moment(dateEnd).format('DD.MM.YYYY') : '')}`
}

export default {getPeriodString, isDone, getPercentDoneText, getPercentIcon}