import React, {Component} from 'react';
import {CONSTANTS} from "../../../constants";
import moment = require("moment");
import {VideoDTO} from "akipkro-share";

interface VideoCardProps {
  video: VideoDTO
}

class VideoCard extends Component<VideoCardProps> {

  getTimeVideo(durability: number): string {
    const time = moment.utc(durability * 1000).format("HH:mm:ss")
    if (time.substring(0, 2) === '00') {
      return time.substring(3)
    } else {
      return time
    }
  }

  render() {
    let {video} = this.props
    return (
      <div className={'video-card mb-primary'}>
        <a href={CONSTANTS.videoUrl + '/video/' + video.videoId}>
          <img className={'video-card-image'} src={CONSTANTS.cdnUrl + '/' + video.thumbnailFileName} alt={'Миниатюра'}/>
        </a>
        <span
          className="badge badge-dark video-card-duration">{this.getTimeVideo(video.durability)}</span>
      </div>
    );
  }
}

export default VideoCard;
