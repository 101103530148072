declare var CDN_SERVER: string
declare var AUTH_SERVER: string
declare var VIDEO_SERVER: string
declare var WIKI_SERVER: string
declare var COURSES_PASSAGE_SERVER: string

const CONSTANTS = {
  baseUrlAPI: '/api',
  authUrl: AUTH_SERVER,
  authUrlApi: AUTH_SERVER + '/api',
  wikiUrl: WIKI_SERVER,
  videoUrl: VIDEO_SERVER,
  coursePassageUrl: COURSES_PASSAGE_SERVER,
  coursePassageUrlApi: COURSES_PASSAGE_SERVER + '/api',
  cdnUrl: CDN_SERVER,
  cdnUrlUpload: CDN_SERVER + '/upload',
  clientId: '769c0003-f85d-40bb-bc67-8e68658309d0',
  clientLogin: 'landing',
  clientPassword: 'aHacaef',
  clientName: 'Открытое образование',
}

const PRIVILEGES = {
  ADMIN: 'ADMIN'
}

export {CONSTANTS, PRIVILEGES}