import React, {Component} from 'react';
import {apiPost, connectComponent, errorActions} from "akipkro-share-client";
import {Button, Col, Container, EmptyContent, Icon, Row} from "akipkro-ui";
import {CONSTANTS} from "../../../../constants";
import CourseCardList from "../../../share/course/CourseCardList";
import {mdiChevronRight} from "@mdi/js";
import {CourseDTO, CourseSearchParamsDTO, CourseSorting} from "akipkro-share";

interface CoursesPreviewProps {
  errorActions: any,
  selectedLang: string
}

interface CoursesPreviewState {
  courses: CourseDTO[],
}

const ICON = (
  <svg width="322" height="327" viewBox="0 0 322 327" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path
        d="M32.6533 264.192H58.8654V52.1816H32.6533V27.4595H145.74V52.1816H119.528V141.705H153.229L177.569 85.5189C182.811 73.5324 187.929 63.5437 192.921 55.5528C197.914 47.5618 203.032 41.194 208.274 36.4494C213.517 31.455 219.009 27.959 224.75 25.9612C230.492 23.9635 236.608 22.9646 243.099 22.9646C253.584 22.9646 261.946 26.0861 268.187 32.329C274.678 38.572 277.923 46.4381 277.923 55.9273C277.923 66.4155 274.928 74.6561 268.936 80.6494C263.195 86.3929 255.706 89.2646 246.469 89.2646C238.231 89.2646 231.74 87.142 226.997 82.8968C222.504 78.4019 219.633 73.1579 218.385 67.1646H216.887C214.64 70.6607 212.019 75.0307 209.023 80.2748C206.277 85.5189 203.531 91.3872 200.785 97.8799L178.318 148.822C184.558 149.322 190.175 150.445 195.168 152.193C200.161 153.692 204.779 156.064 209.023 159.31C213.267 162.557 217.136 166.552 220.631 171.297C224.126 176.042 227.621 181.91 231.116 188.902L268.187 264.192H291.404V288.914H213.517L153.229 167.551H119.528V264.192H145.74V288.914H32.6533V264.192Z"
        fill="#999CE3"/>
    </g>
    <path
      d="M25.1182 258.278H51.3302V46.2675H25.1182V21.5455H138.205V46.2675H111.992V135.791H145.694L170.033 79.6048C175.276 67.6184 180.393 57.6297 185.386 49.6387C190.379 41.6477 195.497 35.2799 200.739 30.5353C205.981 25.5409 211.473 22.0449 217.215 20.0472C222.957 18.0494 229.073 17.0505 235.564 17.0505C246.048 17.0505 254.411 20.172 260.652 26.4149C267.143 32.6579 270.388 40.524 270.388 50.0133C270.388 60.5014 267.393 68.7421 261.401 74.7353C255.66 80.4788 248.17 83.3506 238.934 83.3506C230.696 83.3506 224.205 81.228 219.462 76.9828C214.968 72.4879 212.098 67.2438 210.849 61.2506H209.352C207.105 64.7466 204.484 69.1167 201.488 74.3607C198.742 79.6048 195.996 85.4732 193.25 91.9658L170.782 142.908C177.023 143.408 182.64 144.531 187.633 146.279C192.626 147.778 197.244 150.15 201.488 153.396C205.732 156.643 209.601 160.638 213.096 165.383C216.591 170.127 220.086 175.996 223.581 182.988L260.652 258.278H283.869V283H205.981L145.694 161.637H111.992V258.278H138.205V283H25.1182V258.278Z"
      fill="white"/>
    <defs>
      <filter id="filter0_d" x="2.65332" y="0.9646" width="318.751" height="325.949" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="15"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.201444 0 0 0 0 0.191354 0 0 0 0 0.695833 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
)

class CoursesPreview extends Component<CoursesPreviewProps, CoursesPreviewState> {
  constructor(props) {
    super(props);
    this.state = {
      courses: null
    }
  }


  componentDidMount(): void {
    this.loadData()
  }

  loadData = async () => {
    const {errorActions, selectedLang} = this.props

    const params: CourseSearchParamsDTO = {
      isHighQuality: true,
      limit: 3,
      sorting: CourseSorting.POPULAR
    }


    let courses: CourseDTO[] = await apiPost(
      CONSTANTS.coursePassageUrlApi + '/course/search' + (selectedLang ? ('/' + selectedLang) : ''),
      params,
      null,
      errorActions.setError
    )
    this.setState({
      courses
    })
  }

  render() {
    let {courses} = this.state
    return (
      <div className={'courses-preview'}>
        <div className={'courses-preview-header'}>
          <Container>
            <Row>
              <Col xs={12} md={4} className={'text-center flex-shrink-0'}>
                <div className={'courses-icon'}>
                  <a href={CONSTANTS.coursePassageUrl}>
                    {ICON}
                  </a>
                </div>
              </Col>
              <Col xs={12} md={8}>
                <h2 className={'mb-primary header'}>
                  <a href={CONSTANTS.coursePassageUrl}>
                    Образовательные курсы
                  </a>
                </h2>
                <p className={'mb-0 description'}>
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        {courses &&
        <div className={'courses-preview-cards'}>
          <Container className={'d-flex flex-column '}>
            <CourseCardList courses={courses}/>
            <div className={'my-primary d-flex justify-content-center'}>
              <Button tag={'a'} href={CONSTANTS.coursePassageUrl} color={'indigo'}
                      className={'d-flex align-items-center'}>
                Все курсы
                <Icon path={mdiChevronRight} color={'white'} className={'ml-secondary'}/>
              </Button>
            </div>
            {courses.length === 0 &&
            <div className={'py-4'}/>}
          </Container>
        </div>
        }
      </div>
    )
  }
}

export default connectComponent(
  (state, props) => ({}),
  dispatch => ({
    errorActions: errorActions(dispatch)
  }),
  CoursesPreview
);
