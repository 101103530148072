import React, {Component} from 'react';
import {Card, CardBody, CardFooter} from "akipkro-ui";
import {HtmlView} from "akipkro-ckeditor";
import {CONSTANTS} from "../../../constants";
import {PracticeDTO} from "akipkro-share";

interface ExamCardProps {
  practice: PracticeDTO,
}

class PracticeCard extends Component<ExamCardProps, {}> {

  render() {
    const {practice} = this.props
    return (
      <Card className={'practice-card'} onClick={() => {
        window.location.href = CONSTANTS.coursePassageUrl + '/practice/' + practice.practiceId
      }}>
        <CardBody className={'practice-card-body'}>
          <img src={CONSTANTS.cdnUrl + '/' + practice.imageFileName} width={'100%'} height={'auto'}/>
          <div className={'block-text-gradient'}>
            <h4>
              {practice.name}
            </h4>
            <p className={'mx-secondary ml-0 mb-0'}>
              {practice.shortDescription}
            </p>
            <div className={'gradient'}/>
          </div>
        </CardBody>
        <CardFooter className={'practice-destination-card-footer '}>
          <h4 className={'mb-0'}>
            {practice.organizationName}
          </h4>
        </CardFooter>
      </Card>
    )
  }
}

export default PracticeCard;
