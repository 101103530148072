import React, {Component} from 'react';
import {
  COLORS,
  Icon
} from "akipkro-ui";
import {mdiPdfBox, mdiLinkVariant} from '@mdi/js'
import {CONSTANTS} from "../../../constants";
import {MaterialDTO} from "akipkro-share";

interface MaterialCardProps {
  material: MaterialDTO,
  url: string
}

class MaterialCard extends Component<MaterialCardProps, {}> {

  render() {
    let {material, url} = this.props
    return (
      <div className={'material-card d-flex flex-column w-100'}>
        <a className={'icon'} href={url}>
          <img src={CONSTANTS.cdnUrl + '/' + material.pictureFileName} width={'100%'} height={'auto'}/>
        </a>
        <div className={'content pt-secondary'}>
          <a href={url} className={'flex-grow-1'}>
            <h4 className={'name mb-secondary'}>
              {material.name}
            </h4>

            <p className={'description mb-secondary'}>
              {material.description}
            </p>
          </a>
        </div>
        <div className={'content-type'}>
          <div>
            <Icon path={material.contentFileName ? mdiPdfBox : mdiLinkVariant} color={COLORS.gray600}
                  className={'mr-1'}
            />
            {material.contentFileName ? 'Файл' : 'Ссылка'}
          </div>
        </div>
      </div>
    );
  }
}

export default MaterialCard;
