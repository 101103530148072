import React, {Component} from 'react';

interface CourseCardFooterItemProps {
  className?: string
}

class CourseCardFooterItem extends Component<CourseCardFooterItemProps> {
  render() {
    const {className} = this.props
    return (
      <div className={'d-flex align-items-center mt-secondary ' + (className ? className : '')}>
        {this.props.children}
        </div>
    );
  }
}

export default CourseCardFooterItem;