import React, {Component} from 'react';
import {apiGet, connectComponent, errorActions, getSelectedLang} from "akipkro-share-client";
import {Button, Col, Container, Icon, Row} from "akipkro-ui";
import {CONSTANTS} from "../../../../constants";
import {mdiChevronRight} from "@mdi/js";
import ArticlePreviewRender from "../../../share/wiki/ArticlePreviewRender";
import {ArticleVersionDTO} from "akipkro-share";

const ICON = (
  <svg width="319" height="322" viewBox="0 0 319 322" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path
        d="M144.411 283.412C129.495 283.412 116.263 281.968 104.714 279.08C93.407 275.951 83.9038 271.98 76.2051 267.167C68.5064 262.113 62.612 256.458 58.5221 250.2C54.6727 243.703 52.748 236.964 52.748 229.985C52.748 220.358 55.3945 212.778 60.6874 207.242C65.9802 201.707 72.9572 198.939 81.6183 198.939C91.2417 198.939 98.4593 201.827 103.271 207.603C108.323 213.139 110.849 219.997 110.849 228.18C110.849 232.993 109.646 237.566 107.241 241.898C104.835 245.989 101.707 249.238 97.8578 251.644V253.81C101.467 255.254 106.278 256.458 112.293 257.42C118.548 258.383 125.525 258.864 133.224 258.864C175.567 258.864 196.738 236.964 196.738 193.164V162.479H114.097V136.488H196.738V116.272C196.738 104.721 195.415 94.7331 192.769 86.31C190.122 77.8868 186.393 71.028 181.581 65.7334C176.77 60.1982 171.116 56.107 164.62 53.4597C158.124 50.8124 150.907 49.4888 142.968 49.4888C129.495 49.4888 118.909 52.4971 111.21 58.5136C103.752 64.5301 100.023 73.3143 100.023 84.866V101.472H58.1612V27.1073H100.023V48.0448H101.827C107.12 40.3437 114.097 34.2068 122.758 29.6342C131.66 25.0617 143.569 22.7754 158.485 22.7754C172.92 22.7754 186.393 25.543 198.904 31.0782C211.414 36.6134 222.24 44.7959 231.383 55.6257C240.765 66.4554 247.983 80.0528 253.035 96.4178C258.328 112.542 260.975 131.314 260.975 152.733C260.975 174.392 257.967 193.404 251.953 209.769C246.179 226.134 237.999 239.852 227.413 250.922C217.068 261.752 204.798 269.935 190.603 275.47C176.409 280.764 161.011 283.412 144.411 283.412Z"
        fill="white"/>
    </g>
    <path
      d="M151.059 277.712C136.142 277.712 122.91 276.268 111.362 273.38C100.054 270.251 90.5513 266.281 82.8526 261.467C75.1538 256.413 69.2595 250.758 65.1696 244.501C61.3202 238.003 59.3955 231.264 59.3955 224.285C59.3955 214.659 62.0419 207.078 67.3348 201.543C72.6277 196.007 79.6047 193.24 88.2657 193.24C97.8892 193.24 105.107 196.128 109.918 201.904C114.971 207.439 117.497 214.298 117.497 222.48C117.497 227.293 116.294 231.866 113.888 236.198C111.482 240.289 108.355 243.538 104.505 245.945V248.111C108.114 249.555 112.926 250.758 118.94 251.721C125.196 252.683 132.173 253.164 139.871 253.164C182.214 253.164 203.386 231.264 203.386 187.464V156.78H120.745V130.788H203.386V110.573C203.386 99.0209 202.063 89.0334 199.416 80.6103C196.77 72.1871 193.041 65.3283 188.229 60.0337C183.417 54.4985 177.763 50.4073 171.268 47.76C164.772 45.1127 157.554 43.7891 149.615 43.7891C136.142 43.7891 125.556 46.7974 117.858 52.8139C110.4 58.8304 106.671 67.6146 106.671 79.1663V95.772H64.8087V21.4076H106.671V42.3451H108.475C113.768 34.644 120.745 28.5071 129.406 23.9345C138.307 19.362 150.216 17.0757 165.133 17.0757C179.568 17.0757 193.041 19.8433 205.551 25.3785C218.062 30.9137 228.888 39.0962 238.03 49.926C247.413 60.7557 254.63 74.3531 259.683 90.7181C264.976 106.842 267.622 125.614 267.622 147.033C267.622 168.692 264.615 187.705 258.6 204.07C252.826 220.435 244.646 234.152 234.06 245.223C223.715 256.052 211.445 264.235 197.251 269.77C183.056 275.065 167.659 277.712 151.059 277.712Z"
      fill="#F0AD4E"/>
    <defs>
      <filter id="filter0_d" x="22.748" y="0.775391" width="268.227" height="320.636" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="15"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.904167 0 0 0 0 0.329569 0 0 0 0 0.203438 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
)

interface WikiPreviewProps {
  errorActions: any,
}

interface WikiPreviewState {
  articles: ArticleVersionDTO[],
}

class WikiPreview extends Component<WikiPreviewProps, WikiPreviewState> {
  constructor(props) {
    super(props);
    this.state = {
      articles: null
    }
  }

  componentDidMount(): void {
    this.loadData()
  }

  loadData = async () => {
    const {errorActions} = this.props
    const articles: ArticleVersionDTO[] = await apiGet(
      CONSTANTS.wikiUrl + `/api/articleVersion/getLastArticles`,
      {
        langId: getSelectedLang() || '',
      },
      errorActions.setError
    )
    this.setState({articles: articles.splice(0, 2)})
  }

  render() {
    let {articles} = this.state
    return (
      <div className={'wiki-preview'}>
        <div className={'wiki-preview-header'}>
          <Container>
            <Row>
              <Col xs={12} md={8} className={'order-1 order-sm-0'}>
                <h2 className={'mb-primary header'}>
                  <a href={CONSTANTS.wikiUrl} className={'text-orange'}>
                  Открытая Энциклопедия русского языка и культуры
                  </a>
                </h2>
                <p className={'mb-primary description'}>
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                </p>
              </Col>
              <Col xs={12} md={4} className={'text-center flex-shrink-0 order-0 order-sm-1'}>
                <div className={'wiki-icon'}>
                  <a href={CONSTANTS.wikiUrl}>
                    {ICON}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {articles &&
        <div className={'wiki-preview-cards'}>
          <Container className={'d-flex flex-column '}>
            <div className={'d-flex flex-wrap article-preview-container'}>
              {articles.map(item => (
                <ArticlePreviewRender key={item.articleVersionId} articleVersion={item}/>
              ))}
            </div>
            <div className={'my-primary d-flex justify-content-center'}>
              <Button tag={'a'} href={CONSTANTS.wikiUrl} color={'orange'} className={'text-white d-flex align-items-center'}>
                Все статьи
                <Icon path={mdiChevronRight} color={'white'} className={'ml-secondary'}/>
              </Button>
            </div>
          </Container>
        </div>
        }
      </div>
    );
  }
}

export default connectComponent(
  (state, props) => ({}),
  dispatch => ({
    errorActions: errorActions(dispatch)
  }),
  WikiPreview
);
