import React, {Component} from 'react';
import MaterialCard from "./MaterialCard";
import {Col, Row} from "akipkro-ui";
import {CONSTANTS} from "../../../constants";
import {MaterialDTO} from "akipkro-share";

interface MaterialCardListProps {
  materials: MaterialDTO[]
}

class MaterialCardList extends Component<MaterialCardListProps> {

  renderList = (): React.ReactNode => {
    let {materials} = this.props
    let items: React.ReactNode[] = materials.map((material: MaterialDTO) => (
      <Col xl={4} lg={4} md={6} sm={12} xs={12}
           key={material.materialId}>
        <div className={'d-flex justify-content-center w-100'}>
          <MaterialCard material={material}
                        url={CONSTANTS.coursePassageUrl + '/material/' + material.materialId}/>
        </div>
      </Col>
    ))
    return (
      <>
        {items.length ?
          <div className={'d-flex flex-wrap justify-content-center w-100'}>{items}</div>
          :
          null
        }
      </>
    )
  }

  render() {
    return (
      <section className={'d-flex flex-column mb-2'}>
        <Row>
          <Col xl={{offset: 1, size: 10}} lg={{offset: 1, size: 10}} md={12} sm={12} xs={12}>
            {this.renderList()}
          </Col>
        </Row>
      </section>
    )
  }
}

export default MaterialCardList
