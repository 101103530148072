import React, {Component} from 'react';
import {Button, ButtonLink, Card, CardBody, CardFooter} from "akipkro-ui";
import {HtmlView} from "akipkro-ckeditor";
import moment from "moment";
import {CONSTANTS} from "../../../constants";
import {ArticleVersionDTO} from "akipkro-share";


interface ArticlePreviewRenderProps {
  articleVersion: ArticleVersionDTO
}


class ArticlePreviewRender extends Component<ArticlePreviewRenderProps, {}> {
  render() {
    let {articleVersion} = this.props
    moment.locale('ru');
    return (
      <Card className={'article-preview-home mb-primary'}>
        <CardBody className={'article-preview-home-content px-primary pb-0'}>
          <h2 className={'mb-secondary'}>
            <a href={`${CONSTANTS.wikiUrl}/article/view/${articleVersion.articleVersionId}`} className={'category-head-link mb-0'}>
              <span className={'category-head-font-s'}>{articleVersion.name[0]}</span>
              <span className={'text-black'}>{articleVersion.name.slice(1)}</span>
            </a>
          </h2>
          <div className={'block-text-gradient'}>
            <div>
              <HtmlView html={articleVersion.previewText}/>
            </div>
            <div className={'gradient'}/>
          </div>
        </CardBody>
        <CardFooter className={'article-preview-footer '}>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <span className={'article-instruction14 mr-primary'}>Опубликована: {moment(articleVersion.publicDate).format('Do MMMM YYYY')}</span>
            <Button tag={'a'} color={'primary'} outline
                    href={CONSTANTS.wikiUrl + `/article/view/${articleVersion.articleVersionId}`}
            >
              Читать
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default ArticlePreviewRender;
