import React, {Component} from 'react';
import {apiGet, apiPost, connectComponent, errorActions} from "akipkro-share-client";
import {Button, Col, Container, Icon, Row} from "akipkro-ui";
import {CONSTANTS} from "../../../../constants";
import {mdiChevronRight} from "@mdi/js";
import PracticeCardList from "../../../share/practice/PracticeCardList";
import {PracticeDTO} from "akipkro-share";

interface PracticePreviewProps {
  errorActions: any,
  selectedLang: string
}

interface PracticePreviewState {
  practices: PracticeDTO[],
}

const ICON = (
  <svg width="352" height="344" viewBox="0 0 352 344" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path
        d="M30.6484 304.004H147.189V279.343H118.551V53.421H233.899V279.343H205.261V304.004H321.801V279.343H293.163V51.0345H321.801V26.374H30.6484V51.0345H59.2864V279.343H30.6484V304.004Z"
        fill="#2FB9B0"/>
    </g>
    <path
      d="M19.8984 293.306H136.439V268.646H107.801V42.7235H223.149V268.646H194.511V293.306H311.051V268.646H282.413V40.337H311.051V15.6765H19.8984V40.337H48.5364V268.646H19.8984V293.306Z"
      fill="white"/>
    <defs>
      <filter id="filter0_d" x="0.648438" y="4.37402" width="351.153" height="337.63" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="15"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
)

class PracticePreview extends Component<PracticePreviewProps, PracticePreviewState> {
  constructor(props) {
    super(props);
    this.state = {
      practices: null
    }
  }


  componentDidMount(): void {
    this.loadData()
  }

  loadData = async () => {
    const {errorActions, selectedLang} = this.props

    let practices: PracticeDTO[] = await apiGet(
      CONSTANTS.coursePassageUrlApi + '/practice/getPracticesByLimit/3',
      {

      },
      errorActions.setError
    )
    this.setState({
      practices
    })
  }

  render() {
    let {practices} = this.state
    return (
      <div className={'practice-preview'}>
        <div className={'practice-preview-header'}>
          <Container>
            <Row>
              <Col xs={12} md={4} className={'text-center flex-shrink-0'}>
                <div className={'practice-icon'}>
                  <a href={CONSTANTS.coursePassageUrl}>
                    {ICON}
                  </a>
                </div>
              </Col>
              <Col xs={12} md={8}>
                <h2 className={'mb-primary header'}>
                  <a href={CONSTANTS.coursePassageUrl}>
                    Лучшие практики и модели
                  </a>
                </h2>
                <p className={'mb-0 description'}>
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        {practices &&
        <div className={'practice-preview-cards'}>
          <Container className={'d-flex flex-column '}>
            <PracticeCardList practices={practices}/>
            <div className={'my-primary d-flex justify-content-center'}>
              <Button tag={'a'} href={CONSTANTS.coursePassageUrl + '/practice'} color={'primary'}
                      className={'d-flex align-items-center'}>
                Все практики
                <Icon path={mdiChevronRight} color={'white'} className={'ml-secondary'}/>
              </Button>
            </div>
          </Container>
        </div>
        }
      </div>
    );
  }
}

export default connectComponent(
  (state, props) => ({}),
  dispatch => ({
    errorActions: errorActions(dispatch)
  }),
  PracticePreview
);
