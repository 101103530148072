import React, {Component} from 'react';
import {Col, Row} from "akipkro-ui";
import PracticeCard from "./PracticeCard";
import {PracticeDTO} from "akipkro-share";

interface PracticeCardListProps {
  practices: PracticeDTO[],
}

class PracticeCardList extends Component<PracticeCardListProps> {

  constructor(props) {
    super(props);
    this.state = {
      practices: []
    }
  }

  renderList = (): React.ReactNode => {
    let {practices} = this.props
    let items: React.ReactNode[] = practices.map((practice: PracticeDTO) => (
      <Col xl={4} lg={4} md={6} sm={12} xs={12}
           key={practice.practiceId}>
        <div  className={'d-flex justify-content-center w-100'}>
          <PracticeCard practice={practice}/>
        </div>
      </Col>
    ))
    return (
      <div>
        {items.length ?
          <Row className={'d-flex justify-content-center'}>{items}</Row>
          :
          null
        }
      </div>
    )
  }

  render() {
    return (
      <section className={'d-flex flex-column mb-2'}>
        {this.renderList()}
      </section>
    )
  }
}

export default PracticeCardList;
