import React, {Component} from 'react';
import {CONSTANTS} from "../../../constants";

interface CourseCardImageProps {
  imageFileName: string,
  className?: string
}

class CourseCardImage extends Component<CourseCardImageProps> {
  render() {
    let {imageFileName, className} = this.props
    return (
      <img src={CONSTANTS.cdnUrl + '/' + imageFileName} width={348} height={200}
           className={' flex-grow-0 course-card-img ' + (className ? className : '')}
      />
    );
  }
}

export default CourseCardImage;