import React, {Component} from 'react';
import {apiGet, connectComponent, errorActions, getSelectedLang} from "akipkro-share-client";
import {CONSTANTS} from "../../../../constants";
import VideoCard from "../../../share/video/VideoCard";
import {Button, Col, Container, Icon, Row} from "akipkro-ui";
import {mdiChevronRight} from "@mdi/js";
import {VideoDTO} from "akipkro-share";

const ICON = (
  <svg width="319" height="322" viewBox="0 0 319 322" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path
        d="M40.189 259.032H66.2712V48.0727H40.189V23.4731H174.699C189.851 23.4731 202.892 25.0883 213.822 28.3185C224.751 31.3003 233.694 35.5244 240.649 40.991C247.853 46.4576 253.069 52.9181 256.299 60.3725C259.776 67.8269 261.515 75.9025 261.515 84.5993C261.515 93.5446 259.9 101.496 256.671 108.453C253.69 115.411 249.592 121.374 244.375 126.344C239.159 131.065 233.073 135.041 226.118 138.271C219.411 141.501 212.456 143.862 205.252 145.353V147.216C212.704 148.21 220.404 150.198 228.353 153.18C236.302 156.162 243.506 160.262 249.964 165.48C256.423 170.698 261.639 177.158 265.614 184.861C269.836 192.315 271.948 201.137 271.948 211.324C271.948 234.433 264.496 252.323 249.592 264.996C234.688 277.42 212.207 283.632 182.151 283.632H40.189V259.032ZM126.633 257.169H167.992C181.405 257.169 191.714 253.814 198.918 247.105C206.37 240.396 210.096 230.954 210.096 218.779V201.261C210.096 189.085 206.37 179.643 198.918 172.934C191.714 166.225 181.405 162.871 167.992 162.871H126.633V257.169ZM126.633 137.153H161.285C186.87 137.153 199.663 125.35 199.663 101.744V85.3447C199.663 61.7391 186.87 49.9363 161.285 49.9363H126.633V137.153Z"
        fill="#93BCFA"/>
    </g>
    <path
      d="M47.0513 253.148H73.1335V42.1879H47.0513V17.5884H181.561C196.713 17.5884 209.754 19.2035 220.684 22.4337C231.614 25.4155 240.556 29.6397 247.512 35.1062C254.715 40.5728 259.932 47.0333 263.161 54.4877C266.638 61.9421 268.377 70.0177 268.377 78.7145C268.377 87.6598 266.763 95.6112 263.533 102.569C260.553 109.526 256.454 115.49 251.238 120.459C246.021 125.18 239.935 129.156 232.98 132.386C226.273 135.617 219.318 137.977 212.114 139.468V141.332C219.566 142.325 227.267 144.313 235.216 147.295C243.164 150.277 250.368 154.377 256.827 159.595C263.285 164.813 268.501 171.273 272.476 178.976C276.699 186.431 278.81 195.252 278.81 205.439C278.81 228.548 271.358 246.439 256.454 259.111C241.55 271.535 219.07 277.747 189.013 277.747H47.0513V253.148ZM133.495 251.284H174.854C188.268 251.284 198.576 247.93 205.78 241.221C213.232 234.512 216.958 225.069 216.958 212.894V195.376C216.958 183.2 213.232 173.758 205.78 167.049C198.576 160.34 188.268 156.986 174.854 156.986H133.495V251.284ZM133.495 131.268H168.147C193.733 131.268 206.525 119.465 206.525 95.8597V79.46C206.525 55.8543 193.733 44.0515 168.147 44.0515H133.495V131.268Z"
      fill="white"/>
    <defs>
      <filter id="filter0_d" x="10.189" y="1.47314" width="291.759" height="320.159" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="8"/>
        <feGaussianBlur stdDeviation="15"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>

)

interface VideoPreviewProps {
  errorActions: any
}

interface VideoPreviewState {
  videos: VideoDTO[]
}

class VideoPreview extends Component<VideoPreviewProps, VideoPreviewState> {
  constructor(props) {
    super(props);
    this.state = {
      videos: null
    }
  }

  componentDidMount(): void {
    this.loadData()
  }

  loadData = async () => {
    const {errorActions} = this.props
    let videos: VideoDTO[] = await apiGet(
      CONSTANTS.videoUrl + '/api/video/recommended/2',
      {
        langId: getSelectedLang() || '',
      },
      errorActions.setError
    )
    this.setState({videos: videos.splice(0, 2)})
  }

  render() {
    let {videos} = this.state

    let items

    if (videos) {
      items = videos.map(video => (
        <VideoCard key={video.videoId} video={video}/>
      ))
    }

    return (
      <div className={'video-preview'}>
        <div className={'video-preview-header'}>
          <Container>
            <Row>
              <Col xs={12} md={4} className={'text-center flex-shrink-0'}>
                <div className={'video-icon'}>
                  <a href={CONSTANTS.videoUrl}>
                    {ICON}
                  </a>
                </div>
              </Col>
              <Col xs={12} md={8}>
                <h2 className={'mb-primary header'}>
                  <a href={CONSTANTS.videoUrl}>
                    Образовательные видеоуроки
                  </a>
                </h2>
                <p className={'mb-0 description'}>
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                  Получите доступ к постоянно растущей библиотеке онлайн курсов. Смотрите видео уроки и создавайте свои
                  каналы. Станьте читателем или редактором большой энциклопедии сообщества Открытого Образования.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className={'video-preview-cards'}>
          {videos &&
          <div className={'video-list'}>
            {items}
          </div>}

          <div className={'my-primary d-flex justify-content-center'}>
            <Button tag={'a'} href={CONSTANTS.videoUrl} color={'danger'} className={'d-flex align-items-center'}>
              Все видео
              <Icon path={mdiChevronRight} color={'white'} className={'ml-secondary'}/>
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}

export default connectComponent(
  (state, props) => ({}),
  dispatch => ({
    errorActions: errorActions(dispatch)
  }),
  VideoPreview
);
